@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;0,900;1,400&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons+Outlined');
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  @apply text-slate-800
}

.steps {
  position: relative;
  counter-reset: list;
}

.steps>li {
  position: relative;
  padding-left: 3em;
}

.steps>li:not(:first-child) {
  margin-top: .5rem;
}

.steps>li::before {
  position: absolute;
  left: 0;
  counter-increment: list;
  content: counter(list);
  font-size: 14px;
  width: 2em;
  height: 2em;
  padding: .6em;
  margin-right: 1em;
  border-radius: 50%;
  background: #fff;
  color: #ccc;
  border: 1px solid #ccc;
  text-align: center;
  display: inline-flex;
  align-items: center;
}

.steps>li:not(:last-child)::after {
  position: absolute;
  content: "";
  height: calc(100% + .5rem);
  top: 0;
  left: .75em;
  width: 2px;
  background: #e5e5e5;
  z-index: -1;
}

*[class^='material-icons'] {
  vertical-align: text-top;
}

/* Rules for sizing the icon. */
*[class^='material-icons'].md-18 { font-size: 18px; }
*[class^='material-icons'].md-24 { font-size: 24px; }
*[class^='material-icons'].md-24 { font-size: 24px; }
*[class^='material-icons'].md-24 { font-size: 24px; }

/* Rules for using icons as black on a light background. */
*[class^='material-icons'].md-dark { color: rgba(0, 0, 0, 0.54); }
*[class^='material-icons'].md-inactive { color: rgba(0, 0, 0, 0.26); }

/* Rules for using icons as white on a dark background. */
*[class^='material-icons'].md-light { color: rgba(255, 255, 255, 1); }
*[class^='material-icons'].md-inactive { color: rgba(255, 255, 255, 0.3); }

.goto::after {
  content: " »";
}

img {
  image-rendering: optimizeSpeed;
  image-rendering: -moz-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: optimize-contrast;
  image-rendering: pixelated;
  -ms-interpolation-mode: nearest-neighbor;
}
